import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Rollbar from 'rollbar';

import * as segment from '../lib/segment';

import '../styles/globals.css';
import '../styles/styles.scss';
import LinearUnitsInfoProvider from '../utils/use-linear-units';
import HistoryProvider from '../utils/use-history';
import { ViewportProvider } from '../utils/use-viewport';

let isRollbarInitialized = false;

// IMPORTANT: this is a special component in Next.js. Read this before making
// changes here https://nextjs.org/docs/advanced-features/custom-app.
//
// We use this custom app component to load global styles and initialize 3rd
// party libraries that need to run on every page.

function MyApp({ Component, pageProps }) {
    // send pageview events to Segment each time we change routes.
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url: string) => segment.page(url);

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    // initialize Rollbar if we haven't already. We need to check that we're
    // running on the client, and therefore have access to `window`.
    if (
        !isRollbarInitialized &&
        typeof window !== 'undefined' &&
        process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT === 'production'
    ) {
        const config: Rollbar.Configuration = {
            accessToken: '525d8a93da404c91bf0dd9ff303b19bc',
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT,
                client: {
                    javascript: {
                        source_map_enabled: true,
                        code_version: process.env.NEXT_BUILD_ID,
                        guess_uncaught_frames: true,
                    },
                },
            },
        };
        window.rollbar = new Rollbar(config);
        isRollbarInitialized = true;
    }

    return (
        <LinearUnitsInfoProvider>
            <HistoryProvider>
                <ViewportProvider>
                    <Component {...pageProps} />
                </ViewportProvider>
            </HistoryProvider>
        </LinearUnitsInfoProvider>
    );
}

export default MyApp;
