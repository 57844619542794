import React, { createContext, useContext, useEffect, useState } from 'react';
import LOCAL_STORAGE_KEYS from '../components/constants/local-storage';
import { ContextError } from '../lib/errors';

// Dictionary of supported linear units objects
const linearUnitsDict = {
    imperial: {
        systemName: 'imperial',
        units: 'miles',
    },
    metric: {
        systemName: 'metric',
        units: 'kilometers',
    },
    placeholder: {
        systemName: 'placeholder',
        units: '',
    },
};

/** Type to track linear units */
export interface LinearUnitsInfo {
    systemName: string;
    units: string;
}

/** A type representing LinearUnitsInfo plus a method to toggle units */
export interface LinearUnitsContextType extends LinearUnitsInfo {
    toggleLinearUnits: (systemName: string) => void;
}

/** Return the user-selected linear notes from local storage or null if none set */
const getStoredLinearUnits = (): LinearUnitsInfo | null => {
    // Return null if called on the server since there is no local storage
    if (typeof window === 'undefined') {
        return null;
    }
    const storedUnitsString: string | null = localStorage.getItem(
        LOCAL_STORAGE_KEYS.linearUnits
    );
    if (!storedUnitsString) {
        return null;
    }
    // Check for storedUnits and ensure they correspond to a valid system
    try {
        const storedUnits = JSON.parse(storedUnitsString);
        if (!(storedUnits['systemName'] in linearUnitsDict)) {
            return handleInvalidStoredValue();
        }
        return linearUnitsDict[storedUnits['systemName']];
    } catch {
        // The localStorage entry isn't valid, clear it out
        return handleInvalidStoredValue();
    }
};

/** Handle invalid stored data by clearing it out and reverting to the default */
function handleInvalidStoredValue() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.linearUnits);
    return null;
}

/** Get default linear units based on locale */
const getDefaultUnits = (): LinearUnitsInfo => {
    const localeStr = Intl.DateTimeFormat().resolvedOptions().locale;
    // Using miles if user is in America, otherwise use kilometers
    if (localeStr === 'en-US') {
        return linearUnitsDict['imperial'];
    }
    return linearUnitsDict['metric'];
};

/** Return user-selected linear units or the default for their location if none is set */
const getStoredOrDefaultLinearUnits = (): LinearUnitsInfo => {
    const storedUnits = getStoredLinearUnits();
    return storedUnits ? storedUnits : getDefaultUnits();
};

const LinearUnitsContext = createContext<LinearUnitsContextType | null>(null);

/** Provides read-only access to LinearUnitsContext that's available to any component in the app */
export function useLinearUnitsInfo(): LinearUnitsContextType {
    const linearUnits = useContext(LinearUnitsContext);
    if (!linearUnits) throw new ContextError('LinearUnits');
    return linearUnits;
}

interface LinearUnitsInfoProviderProps {
    children: React.ReactNode;
}

/** Component that provides LinearUnitsContext to its children */
function LinearUnitsInfoProvider({ children }: LinearUnitsInfoProviderProps) {
    const [linearUnitsInfo, setLinearUnitsInfo] = useState<LinearUnitsInfo>(
        linearUnitsDict.placeholder
    );
    // Toggles linear units and stores in localStorage is user opt to not use the default for their locale
    const toggleLinearUnits = (currentSystemName: string) => {
        const newUnits: LinearUnitsInfo =
            currentSystemName === 'imperial'
                ? linearUnitsDict['metric']
                : linearUnitsDict['imperial'];
        setLinearUnitsInfo(newUnits);
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.linearUnits,
            JSON.stringify(newUnits)
        );
    };
    const linearUnitsContext = linearUnitsInfo as LinearUnitsContextType;
    linearUnitsContext.toggleLinearUnits = toggleLinearUnits;

    useEffect(() => {
        // Now the page is hydrated, get the actual units
        let newUnits = getStoredOrDefaultLinearUnits();
        setLinearUnitsInfo(newUnits);
    }, []);

    return (
        <LinearUnitsContext.Provider value={linearUnitsContext}>
            {children}
        </LinearUnitsContext.Provider>
    );
}

export default LinearUnitsInfoProvider;
