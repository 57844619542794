import { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface History {
    history: string[];
    setHistory(data: string[]): void;
}

export const HistoryContext = createContext<History>({} as History);

const HistoryProvider = ({ children }) => {
    const router = useRouter();
    const [history, setHistory] = useState<string[]>([]);

    useEffect(() => {
        setHistory(previous => [...previous, router.asPath]);
    }, [router.asPath]);

    return (
        <HistoryContext.Provider
            value={{
                history,
                setHistory,
            }}
        >
            {children}
        </HistoryContext.Provider>
    );
};

export default HistoryProvider;
