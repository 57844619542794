import React, { useState, useEffect, useContext } from 'react';

export const MOBILE_BREAKPOINT = 770;

interface ViewportContext {
    width: number;
    height: number;
}

const viewportContext = React.createContext<ViewportContext>({
    width: -1,
    height: -1,
});

/** Get the current state of the window viewport size */
export const getViewport = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
});

/** Provides the window viewport size to components */
export function ViewportProvider({ children }) {
    const [width, setWidth] = useState(-1);
    const [height, setHeight] = useState(-1);

    const handleWindowResize = () => {
        const viewport = getViewport();
        setWidth(viewport.width);
        setHeight(viewport.height);
    };

    useEffect(() => {
        // ensure we set the size when rendering for the first time on the client
        // if we do this in the setState, window will be undefined when Next.js
        // does SSR
        handleWindowResize();

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
}

/** Hook that gives the current width and height of the viewport */
export function useViewport() {
    const { width, height } = useContext(viewportContext);
    return { width, height };
}
